import React from 'react';
import { Container, Row, Col } from 'react-grid-system';


function Learning(props) {
    return(
        
        <div className="currentlyLearning">
		<span className="smallerText">Currently playing with //</span>
			{ props.learn && <h4>{props.learn}</h4> }
			{ props.updatedOn && <span className="smallerTextOrange">Updated: {props.updatedOn}</span> }
		</div>
        
	);
}


export default Learning