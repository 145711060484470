import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-grid-system';
import Layout from "../components/layout"
import Image from "../components/image"
import Learning from "../components/Learning"
import SEO from "../components/seo"
// import TransitionLink from "gatsby-plugin-transition-link"
//import AniLink from "gatsby-plugin-transition-link/AniLink"





const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
   {/* <h2>Snap!</h2>*/}
   <section style={{ textAlign: `right` }}>
     <div className="learnContainer">
      <Learning learn="Remix, based on React" updatedOn="08/01/2022"/>
     </div>
     
   </section>
   <section className="introSection" style={{
      minHeight: `calc(100vh - 270px)`,
      display: `flex`,
      alignItems: `center`,
      padding: `1.0875rem`
   }}>
     <Container>

       
        <div>
          <p>Hi 👋,</p>
          <p>I&rsquo;m a Front End Engineer based in London, UK. I&rsquo;m currently working for a talented Shopify Partner agency whilst undertaking the odd piece of freelance work. You can view my <span className="linkStyle"><Link to="projects">projects here.</Link></span></p>
          <p>I have plentiful experience with building modern, responsive websites and web applications for small businesses to large corporations, and a <span className="italics">tonne</span> of email development exposure. At the moment I&rsquo;m getting stuck into Remix, built by the team behind React Router.</p>
        </div>
      
      </Container>
    </section>
    {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>*/}
   {/*<AniLink cover to="page-2" bg="#111111">Go to page 2</AniLink>*/}
  </Layout>
)

export default IndexPage
